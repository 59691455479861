export const useKftLocalStorage = <T>(key: string, defaultValue: T) => {
  const data = useState(key, () => defaultValue)
  const loaded = ref(false)

  onMounted(() => {
    let storedData
    try {
      storedData = localStorage.getItem(key)
    } catch (error) {
      console.error('Error accessing local storage', error)
    }
    if (storedData) {
      data.value = JSON.parse(storedData)
    }
    loaded.value = true
  })

  watch(
    data,
    () => {
      try {
        localStorage.setItem(key, JSON.stringify(data.value))
      } catch (error) {
        console.error('Error accessing local storage', error)
      }
    },
    { deep: true }
  )

  return data
}
