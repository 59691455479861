import type { CategoryItem, AlgoliaEventItem, ImageItem } from '../types'
import type { GalleryItem } from '~/composables/types/event'

const fnGetMainCategory = (item: AlgoliaEventItem): string | null => {
  return item?.categories?.[0]?.name || null
}

const fnGetCategories = (item: AlgoliaEventItem): CategoryItem => {
  return item?.categories || null
}

const getPrincipalImage = (item: AlgoliaEventItem): string => {
  return item?.main_image?.thumb || ''
}

const getPrincipalAlt = (item: AlgoliaEventItem): string => {
  return item?.main_image?.alt || ''
}

const fnGetPermalink = (item: AlgoliaEventItem): string => {
  return item?.permalink || ''
}

const fnGetPrice = (item: AlgoliaEventItem, prefix = ''): string => {
  let price = item.default_price?.formatted || null

  const dates = item.dates
  if (typeof dates !== 'undefined' && dates.length > 0) {
    const date = dates[0]

    price = date.price?.formatted || null
  }

  return prefix + ' ' + price
}

const getPriceAmount = (item: AlgoliaEventItem): number =>
  parseInt(item?.default_price?.amount || '0')

const getAverageRating = (
  event: AlgoliaEventItem,
  locale = 'de-DE'
): string | number | null =>
  isGiftcardEvent(event)
    ? null
    : event?.rating
      ? new Intl.NumberFormat(locale, {
          maximumSignificantDigits: 2,
          minimumSignificantDigits: 2,
        }).format(event?.rating)
      : 0

const getBadgeLabel = (
  item: AlgoliaEventItem,
  $t: (key: string) => string
): string => {
  if (item.type === 'NATIONWIDE') {
    return $t('product.card.productBox')
  } else if (item.has_mobile_classes) {
    return $t('product.card.mobileClasses')
  }
  return ''
}

const getRatingsAmount = (event: AlgoliaEventItem): number =>
  event?.totalRating || 0

const fnGetDuration = (item: AlgoliaEventItem): number | null => {
  /* Default duration is in minutes */
  const duration = item.default_duration
  if (duration) {
    return duration
  }

  const dates = item.dates
  if (typeof dates !== 'undefined' && dates.length > 0) {
    const date = dates[0]

    /* Duration is in minutes */
    return date.duration
  }

  return null
}
const fnGetTitle = (item: AlgoliaEventItem): string => {
  return item.title
}

const fnGetSubtitle = (item: AlgoliaEventItem): string => {
  return item.subtitle
}

const fnGetNextDateAvailableStart = (
  item: AlgoliaEventItem
): number | undefined => {
  return item?.next_event || null
}

const fnGetNextDateAvailableEnd = (
  item: AlgoliaEventItem
): number | undefined => {
  const dates = item.dates
  if (typeof dates !== 'undefined' && dates.length > 0) {
    const date = dates[0]

    return date.start_date
  }

  return undefined
}

const fnGetType = (
  item: AlgoliaEventItem
): 'HYBRID' | 'IN_PERSON' | 'ONLINE' | 'NATIONWIDE' => {
  return item.type || ''
}

const fnGetMinimumPriceAmount = (item: AlgoliaEventItem): number => {
  return item.min_price
    ? parseInt(item.min_price.amount)
    : parseInt(item.default_price.amount)
}

const fnGetMaximumPriceAmount = (item: AlgoliaEventItem): number => {
  return item.max_price && Number(item.max_price.amount) > 0
    ? parseInt(item.max_price.amount)
    : getPriceAmount(item)
}

const fnGetMinimumPriceCurrency = (item: AlgoliaEventItem): string => {
  return item.min_price ? item.min_price.currency : item.default_price.currency
}

const fnGetMaximumPriceCurrency = (item: AlgoliaEventItem): string => {
  return item.max_price ? item.max_price.currency : item.default_price.currency
}

const fnGetSupplier = (item: AlgoliaEventItem): string => {
  return item?.supplier || ''
}

const getSupplierAlias = (item: AlgoliaEventItem): string | undefined => {
  return item?.supplier_alias || undefined
}

const getSupplierId = (item: AlgoliaEventItem): string | undefined => {
  return item?.supplier_id || undefined
}

const getSupplierPermalink = (item: AlgoliaEventItem): string | undefined => {
  return item?.supplier_permalink || undefined
}

const fnGetCity = (item: AlgoliaEventItem): string => {
  return item.city || ''
}

const fnGetCitySlug = (item: AlgoliaEventItem): string => {
  return item.city_slug || ''
}

const fnGetFormattedAddress = (item: AlgoliaEventItem): string => {
  return item.address?.formatted || null
}

const fnGetFormattedZipcode = (item: AlgoliaEventItem): string => {
  return item?.address?.zipcode || ''
}

const fnGetHashedId = (item: AlgoliaEventItem): string => {
  return item?.hashed_id || ''
}

const getNextDate = (item: AlgoliaEventItem): number | null => {
  const allDates = [
    ...(item?.dates_ts ?? []),
    ...(item?.dates_private_ts ?? []),
  ].sort()
  if (allDates.length === 0) return null
  const now = Date.now()
  const currentTimestampWithoutMilliseconds = ~~(now / 1000)
  const minAdvance = item?.dates?.[0].min_advance ?? 0

  for (const date of allDates) {
    if (date > currentTimestampWithoutMilliseconds + (minAdvance ?? 0) * 60) {
      return date
    }
  }

  return null
}

const isGiftcardEvent = (event: AlgoliaEventItem): boolean =>
  fnGetSupplier(event) === 'konfetti Gutscheine'

const fnGetLink = (
  eventItem: AlgoliaEventItem,
  queryId = null,
  addSupplierLayoutQuery = false
) => {
  let query = null

  let link = `/${
    isGiftcardEvent(eventItem) ? 'giftcard' : 'e'
  }/${fnGetPermalink(eventItem)}/`

  if (addSupplierLayoutQuery) {
    query = 'layout=sup'
  }

  if (queryId) {
    query = query ? `${query}&qid=${queryId}` : `qid=${queryId}`
  }

  if (query) {
    link = `${link}?${query}`
  }

  return link
}

const getRouterPushToEvent = (
  algoliaItem: AlgoliaEventItem,
  queryId: string,
  locale: string,
  addSupplierLayoutQuery = false
) => {
  return {
    name: `${
      isGiftcardEvent(algoliaItem) ? 'giftcard-special' : 'e-eventPermalink'
    }___${locale}`,
    query: {
      ...(queryId && { qid: queryId }),
      ...(addSupplierLayoutQuery && { layout: 'sup' }),
    },
    params: {
      special: fnGetPermalink(algoliaItem),
      eventPermalink: fnGetPermalink(algoliaItem),
      algoliaEventFromPreviousPage: algoliaItem,
    },
  }
}

export const algoliaEventGetters = {
  getPrincipalImage,
  getPrincipalAlt,
  fnGetMainCategory,
  fnGetCategories,
  fnGetPermalink,
  fnGetPrice,
  getPriceAmount,
  getAverageRating,
  getRatingsAmount,
  fnGetDuration,
  fnGetTitle,
  fnGetSubtitle,
  getBadgeLabel,
  fnGetNextDateAvailableStart,
  fnGetNextDateAvailableEnd,
  getNextDate,
  fnGetType,
  fnGetMinimumPriceAmount,
  fnGetMinimumPriceCurrency,
  fnGetSupplier,
  fnGetCity,
  fnGetCitySlug,
  fnGetFormattedAddress,
  fnGetFormattedZipcode,
  fnGetMaximumPriceAmount,
  fnGetMaximumPriceCurrency,
  fnGetHashedId,
  fnGetLink,
  isGiftcardEvent,
  getRouterPushToEvent,
  getSupplierPermalink,
  getSupplierAlias,
  getSupplierId,
}
