import cloneDeep from 'lodash/cloneDeep'
import type { Cart, CartItem, GiftboxPrice } from '~/composables/types/cart'
import { sendAddToCart } from '~/composables/useGtmEvents'

const CART_LOCAL_STORAGE_KEY_BASE = 'konfetti-cart-local-session' as string
const GIFTBOX_PRICE: GiftboxPrice = {
  amount: '295',
  currency: 'EUR',
  formatted: '€ 2.95',
}
export const cartLocalStorageKey = (key: string) =>
  `${CART_LOCAL_STORAGE_KEY_BASE}-${key}`

export const getEmptyCart = (): Cart =>
  cloneDeep<Cart>({
    items: [],
    isGiftboxEnabled: false,
    giftboxPrice: GIFTBOX_PRICE,
    updatedAt: null,
    shippingCost: null,
  })

export enum CartKeys {
  GENERAL = 'general-instance',
  EMBEDDED = 'embedded-instance',
}

export const useCart = (key: CartKeys | string) => {
  const cart = useKftLocalStorage<Cart>(
    cartLocalStorageKey(key),
    getEmptyCart()
  )
  const lastRemoved = ref<CartItem | undefined>(undefined)

  const hasGiftcard = computed(() => cartGetters.hasGiftcard(cart.value))

  const findItem = (obj: CartItem): CartItem | undefined => {
    return cart.value.items.find((item: CartItem) => isTheSameItem(obj, item))
  }

  const updateItemQuantity = (
    obj: CartItem,
    newQuantity: number | string
  ): boolean => {
    const item = findItem(obj)
    const availableTicketsQuantity = Number(item?.availableTicketsQuantity) || 0

    if (!item?.quantity) {
      return false
    }

    if (newQuantity === 0) {
      removeItem(obj)
    }

    if (
      !cartGetters.isGiftcard(item) &&
      availableTicketsQuantity < Number(newQuantity)
    ) {
      item.quantity = availableTicketsQuantity
      return false
    }

    item.quantity = Number(newQuantity)

    return true
  }
  const isTheSameItem = (obj: CartItem, item: CartItem): boolean => {
    if (cartGetters.isGiftcard(obj)) {
      return (
        cartGetters.isGiftcard(item) &&
        cartGetters.getGiftcardId(item) === cartGetters.getGiftcardId(obj)
      )
    }

    return cartGetters.getItemId(item) === cartGetters.getItemId(obj)
  }

  const isInCart = (obj: CartItem): boolean => {
    return cart.value.items.some((item: CartItem) => isTheSameItem(item, obj))
  }

  const addItem = (item: CartItem) => {
    if (isInCart(item)) {
      const cartItem = findItem(item)
      updateItemQuantity(item, (cartItem?.quantity || 0) + item.quantity)
      if (cartItem && item?.addOn) {
        cartItem.addOn = item.addOn
      }

      return cart.value.items.length
    }

    const result = cart.value.items.push(item)

    sendAddToCart(item)

    return result
  }

  const setLastRemovedItemTemporarily = (item: CartItem) => {
    lastRemoved.value = item

    setTimeout(() => {
      lastRemoved.value = undefined
    }, 15000)
  }

  const removeItem = (obj: CartItem) => {
    if (!process.client) {
      return null
    }

    let result = false

    const startingLength = cart.value.items.length
    if (obj) {
      setLastRemovedItemTemporarily(obj)
    }

    const toDeleteIdx = cart.value.items.findIndex((item) =>
      isTheSameItem(obj, item)
    )

    cart.value.items.splice(toDeleteIdx, 1)

    if (startingLength !== cart.value.items.length) {
      result = true
    }

    return result
  }

  const removeAddOn = (obj: CartItem) => {
    if (!process.client) {
      return null
    }

    const indexOfItemToRemoveAddOn = cart.value.items.findIndex((item) =>
      isTheSameItem(obj, item)
    )

    delete cart.value.items?.[indexOfItemToRemoveAddOn]?.addOn
  }

  watchEffect(() => {
    if (!hasGiftcard.value && cart.value.isGiftboxEnabled) {
      cart.value.isGiftboxEnabled = false
    }
  })

  return {
    cart,
    addItem,
    removeItem,
    removeAddOn,
    findItem,
    isInCart,
    updateItemQuantity,
  }
}

// import { Logger, sharedRef, UseKftContext } from '@konfetti-core/core'
// import { computed, onMounted, reactive, watch } from '@vue/composition-api'
// import VsToast from '@vuesimple/vs-toast'
// import { useApiHandler } from '../useApiHandler'
// import { Cart, CartItem } from '../types'
// import { cartGetters } from '../getters/cartGetters'
// import { getSupplierSubdomain } from '../helpers'
//
// const CART_LOCAL_STORAGE_KEY_BASE = 'konfetti-cart-local-session' as string
//
// export const cartLocalStorageKey = (key) => `${CART_LOCAL_STORAGE_KEY_BASE}-${key}` as string
//
// export enum CartKeys {
//   GENERAL = 'general-instance',
//   EMBEDDED = 'embedded-instance',
// }
//
// function getEmptyCart(): Cart {
//   return {
//     items: [],
//     isGiftboxEnabled: false,
//     giftboxPrice: null,
//     updatedAt: null,
//     shippingCost: null,
//   }
// }
//
// export const useCart = (id: string): any => {
//   const context = UseKftContext()
//   const supplierScope = sharedRef(
//     getSupplierSubdomain(context?.ssrContext?.nuxt || context.nuxtState, context.$config),
//     'supplier-scope'
//   )
//
//   const cart = sharedRef<Cart>(null, `useCart-cart-${id}`)
//   const loading = sharedRef(false, `useCart-loading-${id}`)
//   const shippingPrice = sharedRef(4.99, `useCart-shippingPrice-${id}`)
//   const lastRemoved = sharedRef(null, `useCart-lastRemoved-${id}`)
//   const getGiftBoxPricePromise = sharedRef(null, 'useCart-getGiftBoxPricePromise')
//   const cartIsLoaded = computed(() => cart.value !== null)
//   const loadedGiftboxPrice = computed(() => Boolean(cart.value.giftboxPrice))
//
//   /** Wrapper for API requests */
//   const { makeRequest } = useApiHandler()
//
//   const loadGiftboxPrice = async () => {
//     Logger.debug(`useUser/${id}/getGiftboxPrice`)
//
//     try {
//       loading.value = true
//
//       getGiftBoxPricePromise.value = makeRequest('getGiftboxPrice')
//
//       cart.value.giftboxPrice = await getGiftBoxPricePromise.value
//     } catch (err) {
//       Logger.error(`useUser/${id}/login`, err)
//     } finally {
//       loading.value = false
//     }
//   }
//
//   const persistCartInLocalStorage = () => {
//     if (!localStorage) {
//       return
//     }
//
//     localStorage.setItem(cartLocalStorageKey(supplierScope.value || id), JSON.stringify(cart.value))
//   }
//
//   const loadCartFromLocalStorage = () => {
//     const storedCartData = localStorage.getItem(cartLocalStorageKey(supplierScope.value || id))
//
//     if (storedCartData) {
//       const parsedCartData = JSON.parse(storedCartData)
//       cart.value = reactive(parsedCartData)
//
//       return true
//     }
//
//     return false
//   }
//
//   const loadAndSetupGiftboxPrice = async () => {
//     if (loadedGiftboxPrice.value) {
//       return
//     }
//
//     if (!getGiftBoxPricePromise.value) {
//       await loadGiftboxPrice()
//     } else {
//       cart.value.giftboxPrice = await getGiftBoxPricePromise.value
//     }
//   }
//
//   const loadCart = async (): Promise<any> => {
//     Logger.debug(`useCart/${id}/loadCart: Started...`)
//
//     if (!process.client) {
//       return null
//     }
//
//     loading.value = true
//
//     /*
//      * We should not load from localStorage for the embedded situation
//      * to avoid different behaviors
//      *
//      * For embedded checkout, we use iframes so sometimes localStorage is not available
//      * Because of this we need different flow for adding items to cart which assumes localStorage does not exist
//      * We still have to verify because testing the embedded directly through the url (without iframe) might not work
//      * since is this case localStorage will be available
//      */
//     const loadedFromLocalStorage = id !== CartKeys.EMBEDDED ? loadCartFromLocalStorage() : null
//
//     if (!cartIsLoaded.value && !loadedFromLocalStorage) {
//       cart.value = getEmptyCart()
//       Logger.debug(`useCart/${id}/loadCart: Empty Cart, returning...`)
//     }
//
//     await loadAndSetupGiftboxPrice()
//
//     loading.value = false
//   }
//
//   const isTheSameItem = (obj: CartItem, item: CartItem): boolean => {
//     if (cartGetters.isGiftcard(obj)) {
//       return (
//         cartGetters.isGiftcard(item) &&
//         cartGetters.getEventDescriptionId(item) === cartGetters.getEventDescriptionId(obj)
//       )
//     }
//
//     return cartGetters.getItemId(item) === cartGetters.getItemId(obj)
//   }
//
//   const isInCart = (obj: CartItem): boolean => {
//     if (!process.client) {
//       return null
//     }
//
//     if (cart.value === null) {
//       loadCart()
//     }
//
//     return cartItems.value.some((item) => isTheSameItem(item, obj))
//   }
//
//   const findItem = (obj: CartItem): CartItem => {
//     return cartItems.value.find((item) => isTheSameItem(obj, item))
//   }
//
//   const updateItemQuantity = (obj: CartItem, newQuantity): boolean => {
//     if (!process.client) {
//       return false
//     }
//
//     if (cart.value === null) {
//       loadCart()
//     }
//
//     const item = findItem(obj)
//
//     if (!item?.quantity) {
//       return false
//     }
//
//     if (!cartGetters.isGiftcard(item) && item.availableTicketsQuantity < newQuantity) {
//       VsToast.show({
//         title: context.i18n.t('cart.notEnoughTicketsError.title'),
//         variant: 'error',
//         position: 'bottom-right',
//         message: context.i18n.t('cart.notEnoughTicketsError.description'),
//       })
//
//       newQuantity = item.availableTicketsQuantity
//     }
//
//     item.quantity = newQuantity
//
//     return true
//   }
//
//   const addItem = (item): number => {
//     if (!process.client) {
//       return null
//     }
//
//     Logger.debug(`useCart/${id}/addItem: Starting`, item)
//     loading.value = true
//
//     if (isInCart(item)) {
//       const cartItem = findItem(item)
//       updateItemQuantity(item, cartItem.quantity + item.quantity)
//
//       return cartItems.value.length
//     }
//
//     const result = cartItems.value.push(item)
//
//     loading.value = false
//     Logger.debug(`useCart/${id}/addItem: Item Added`, item)
//
//     return result
//   }
//
//   const fnUndoRemoveItem = () => {
//     if (lastRemoved.value) {
//       cartItems.value.push(lastRemoved.value)
//
//       lastRemoved.value = null
//     }
//   }
//
//   const setLastRemovedItemTemporarily = (item) => {
//     lastRemoved.value = item
//
//     setTimeout(() => {
//       lastRemoved.value = null
//     }, 15000)
//   }
//
//   const removeItem = (obj) => {
//     if (!process.client) {
//       return null
//     }
//
//     Logger.debug(`useCart/${id}/removeItem:`, cartGetters.getItemId(obj))
//     let result = false
//     loading.value = true
//
//     if (cart.value === null) {
//       loadCart()
//     }
//
//     const startingLength = cartItems.value.length
//     if (obj) {
//       setLastRemovedItemTemporarily(obj)
//     }
//
//     cartItems.value = cartItems.value.filter((item) => !isTheSameItem(obj, item))
//
//     if (startingLength !== cartItems.value.length) {
//       result = true
//     }
//
//     loading.value = false
//
//     return result
//   }
//
//   const clear = () => {
//     if (!process.client) {
//       return null
//     }
//
//     cart.value = getEmptyCart()
//
//     return true
//   }
//
//   const applyCoupon = () => {
//     if (!process.client) {
//       return null
//     }
//
//     return null
//   }
//
//   const removeCoupon = () => {
//     if (!process.client) {
//       return null
//     }
//
//     return null
//   }
//
//   const getItem = (itemId: string) => {
//     return cartItems.value.find((item) => item.id === itemId)
//   }
//
//   const toggleGiftbox = () => {
//     cart.value.isGiftboxEnabled = !cart.value.isGiftboxEnabled
//
//     if (!process.client) {
//       return null
//     }
//   }
//
//   const setShippingPrice = (price) => {
//     shippingPrice.value = price
//   }
//
//   const fnSetShippingCost = (newCountryObj: any) => {
//     cart.value = {
//       ...cart.value,
//       shippingCost: newCountryObj,
//     }
//   }
//
//   onMounted(async () => {
//     await loadCart()
//   })
//
//   // Save cart to local storage on every change
//   watch(
//     cart,
//     () => {
//       persistCartInLocalStorage()
//     },
//     { deep: true, immediate: false }
//   )
//
//   return {
//     // Variables
//     cart: computed(() => cart.value),
//     loading: computed(() => loading.value),
//     needsShipping: computed(() => {
//       return cart?.value?.isGiftboxEnabled || cart?.value?.items.some((item) => item.requiredAddress === 1)
//     }),
//     shippingPrice: computed(() => shippingPrice.value),
//     lastRemoved: computed(() => lastRemoved.value),
//     cartIsEmpty: computed(() => cartItems.value?.length === 0 || false),
//
//     // Methods
//     loadCart,
//     updateItemQuantity,
//     isInCart,
//     addItem,
//     removeItem,
//     clear,
//     fnSetShippingCost,
//     applyCoupon,
//     removeCoupon,
//     getItem,
//     toggleGiftbox,
//     setShippingPrice,
//     loadGiftboxPrice,
//     fnUndoRemoveItem,
//   }
// }
