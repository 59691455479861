import type { AlgoliaEventItem } from '~/composables/types/algolia'

export const parseKftAlgoliaEventItemToGtmItemListItem = (
  data: AlgoliaEventItem
) => {
  const eventTitle = algoliaEventGetters.fnGetTitle(data)
  const eventId = algoliaEventGetters.fnGetHashedId(data)
  const partnerName = algoliaEventGetters.fnGetSupplier(data)
  const category = algoliaEventGetters.fnGetMainCategory(data)
  const eventPrice = algoliaEventGetters.getPriceAmount(data)

  return {
    item_id: eventId,
    item_name: eventTitle,
    item_category: category,
    item_brand: partnerName,
    price: (eventPrice / 100).toFixed(2),
  }
}
