<template>
  <NuxtErrorBoundary @error="handleError">
    <slot />
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

const handleError = (e: unknown) => {
  if (config.public.appEnv !== 'production') {
    console.error(e)
  }
}
</script>
