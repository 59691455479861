import type {
  EventAddOn,
  EventAddOnInventory,
  EventAddOnPrice,
} from '~/composables/types/event'

export const getEventAddOnUnitPriceAmount = (
  addon: EventAddOn
): number | null => parseInt(addon?.unit_price?.amount as string, 10) ?? null

export const getEventAddOnInventoryType = (
  addon: EventAddOn
): EventAddOnInventory | undefined => addon?.inventory_type

export const getEventAddOnPriceType = (
  addon: EventAddOn
): EventAddOnPrice | undefined => addon?.price_type

export const getEventAddOnId = (addon: EventAddOn): string | undefined =>
  addon?.id

export const getEventAddOnName = (addon: EventAddOn): string | undefined =>
  addon?.name
